:root {
  --green: #009C56;
  --dark-green: #4F6C51;
  --gold: #F8C11E;
  --floral-white: #FDFBEF;
  --red-alert: #EA272F;
  --blue:#103352;  
  --radius: 10px;
}

.App {
  position: relative;
  max-width: 600px;
  margin: 0px auto;
}
@media only screen and (max-width: 600px) {
  .App {
    max-width: 100%;
  }
  .floating-question { display: none }
  .intro {
    font-size: 1.2em;
    position: relative !important;
    width: auto !important;
  }
  .afronding {
    position: relative !important;
    width: auto !important;
  }
  .afrondingResultaten {
    padding: 0px !important;
  }
  .afronding .afrondingText {
    padding: 15px !important;
    margin: 15px auto !important;
  }
  .intro .introText {
    padding: 10px 10px 25px 10px !important;
  }
  .vraagOpties ul {
    padding: 10px 10px 10px 10px !important;
  }

}
.intro {
  position: fixed;
  padding: 0px 15px 0px 15px;
  top: 25%;
  text-align: center;
  user-select: none;
  margin-right: auto;
  margin-left: auto;
  width: 50%;
  min-width: 250px;
  right: 0;
  left: 0;
}

.intro .introText {
  background: var(--floral-white);
  padding: 25px;
  border-radius: var(--radius);
  color: var(--blue);  
  font-weight: 600;
  max-width: 600px;
  margin: 15px auto;
  animation: fadeInText .5s;
}
.intro .introText .title {
  animation: boom 1s;
  animation-delay: 2s;
  display: inline-block;
}
.intro .introText .sub-title {
  display: inline-block;
  max-width: 400px;
}

@keyframes boom {
  0% {transform: scale(1);}
  25% {transform: scale(1.01);}
  50% {transform: scale(1);}
  75% {transform: scale(1.01);}
  100% {transform: scale(1);}
}

.intro .introStart {
  background: var(--green);
  color: var(--floral-white);
  cursor: pointer;
  max-width: 600px;
  padding: 25px;
  margin: 15px auto;
  text-align: center;
  line-height: 15px;
  border-radius: var(--radius);
  font-weight: 600;
  font-size: 1.5em;
}
.intro .introStart .start {
  animation: boom2 6s infinite ease-in-out;
}

@keyframes boom2 {
  0% {transform: scale(1);}
  25% {transform: scale(1.08);}
  50% {transform: scale(1);}
  75% {transform: scale(1.08);}
  100% {transform: scale(1);}
}
.intro .deelMe {
  padding: 0px 10px 25px 10px;
}

.afronding {
  position: relative;
  max-width: inherit;
  padding: 100px 15px 0px 15px;
  text-align: center;
  user-select: none;
  margin-right: auto;
  margin-left: auto;
  width: 50%;
  min-width: 250px;
  right: 0;
  left: 0;
}

.afronding .afrondingText {
  background: var(--floral-white);
  padding: 25px;
  border-radius: var(--radius);
  color: var(--blue);  
  font-weight: 600;
  max-width: 600px;
  margin: 15px auto;
  animation: fadeInText .5s;
  position: relative;
}

.afronding .afrondingText .deelbaar-notify {
  user-select: none;
  transform: rotateZ(1deg);
  height: 30px;
  vertical-align: middle;
  display: inline-block;
  position: relative;
  margin-top: 15px;
  line-height: 30px;
  color: var(--blue);
}
.afronding .afrondingText .deelbaar-notify .notify-hand {
  display: inline-block;
  font-size: 2em;
  position: absolute;
  animation: notify 2000ms infinite;
  transform: rotateZ(-35deg) translate(-6px, -2px);
  top: -15px;  
}

@keyframes notify {
  0%   { transform: rotateZ(-35deg) translate(-6px, -10px);}
  50%  { transform: rotateZ(-35deg) translate(-6px, -6px);}
  100% { transform: rotateZ(-35deg) translate(-6px, -10px);}
}

.afronding ul.afrondingResultaten {
  list-style-type: none;
  padding: 5px 0px 5px 0px;
  margin: 0px;
}
.afronding ul.afrondingResultaten li {
  background: var(--green);
  text-align: left;
  padding: 0px;
  margin: 0px 0px 5px 0px;
  border-radius: var(--radius);
  color: var(--floral-white);
  font-weight: 600;
}
.afronding ul.afrondingResultaten li img {
  height: 60px;
  width: 60px;
  margin: 0px 0px 0px 10px;
  border-radius:var(--radius);
  vertical-align: top;
  object-fit: cover;
}
.afronding ul.afrondingResultaten li div {
  display: table-cell;
  vertical-align: middle;
  padding: 10px 10px 10px 0px ;
}

.afronding ul.afrondingResultaten li:last-child {
  margin: 0px 0px 0px 0px;
}

.afronding ul.afrondingResultaten li.goud {
  background: var(--green);
}
.afronding ul.afrondingResultaten li.fout {
  background: var(--red-alert);
}
.afronding ul.afrondingResultaten li.goud .correct {
  display: inline-block;
}
.afronding ul.afrondingResultaten li.fout .incorrect{
  text-decoration: line-through;
  display: inline-block;
  font-weight: 400;
}
.afronding ul.afrondingResultaten li.fout .correct{
  display: block;
  transform: rotateZ(-4deg);
}

.afronding .persoonlijk {
  margin: 200px 0px 0px 0px;
  padding: 0px 10px 25px 10px;
}

.vraagKader {
  position: relative;
  max-height: 100%;
  user-select: none;
  margin-bottom: 25%;
}
.vraagKader img {
  width: calc(100% - 30px);
  display: block;
  height: 100%;
  object-fit: cover;
  animation: fadeIn 1s ease-out;
  margin: 15px;
  max-width: 800px;
  border-radius: var(--radius);
}

@keyframes fadeIn {
  0% {opacity: 0; transform: scale(0.97); }
  100% {opacity: 1;  transform: scale(1); }
}

.vraagKader .imgPlaceholder {
  width: calc(100% - 30px);
  display: block;
  height: 300px;
  margin: 15px;
  max-width: 800px;
}

.vraagKader .niveauBadge {
  color: var(--floral-white);
  border-radius: 4px;
  margin: 5px;
  padding: 5px;
  position: absolute;
  float: right;
  right: 5px;
  top: -10px;
  line-height: 10px;
  font-size: .8em;
  font-weight: 600;
  text-transform: uppercase;
  z-index: 900;
  animation: scaleUp 2500ms;
}
.niveauBadge.moeilijk { background: var(--red-alert); }
.niveauBadge.makkelijk { background: var(--green); }
.niveauBadge.gemiddeld { background: var(--blue); }

.vraagKader .nummerBadge {
  color: var(--floral-white);
  background: var(--dark-green);
  border-radius: 20px;
  position: absolute;
  float: right;
  left: 10px;
  top: -5px;
  line-height: 30px;
  font-size: .8em;
  font-weight: 800;
  text-transform: uppercase;
  z-index: 900;
  width: 30px;
  height: 30px;
  text-align: center;
  overflow: hidden;
}

.vraagKader .nummerBadge span {
  animation: countUp 300ms;
  display: inline-block;
}

@keyframes countUp {
  0% {transform: translate(0px, -20px);}
  100% { transform: translate(0px, 0px);}
}



@keyframes scaleUp {
  0% {transform: scale(0); opacity: 0;}
  70% {transform: scale(0); opacity: 0;}
  80% {transform: scale(1.05); opacity: 1;}
  100% {transform: scale(1); opacity: 1;}
}

.vraagOpties {
  background: var(--floral-white);
  border-radius: var(--radius);
  bottom: calc(-100%);
  margin: 15px;
  z-index: 800;
  position: relative;
}

@keyframes fadeInText {
  0% {opacity: 0; transform: scale(0.9);}
  50% {opacity: 1; transform: scale(1.02);}
  100% {opacity: 1; transform: scale(1);}
}

.vraagOpties .vraag {
  padding: 15px 25px 0px 25px;
  font-size: 1.2em;
  color: var(--blue);
  font-weight: 600;
  text-align: center;
  animation: fadeInText 500ms;
}

.vraagOpties ul {
  list-style-type: none;
  padding: 10px 20px 20px 20px;
  margin: 0px; 
  animation: showAnswers 2s;
}

@keyframes showAnswers {
  0% {opacity: 0;}
  50% {opacity: 1;}
  100% {opacity: 1;}
}

.vraagOpties ul li {
  background: var(--green);
  text-align: left;
  padding: 10px;
  margin: 0px 0px 5px 0px;
  border-radius: var(--radius);
  color: var(--floral-white);
  font-weight: 600;
  cursor: pointer;
  animation: stagger 1.5s;
}

.vraagOpties ul li:nth-child(1) {
  animation-delay: 100ms;
}

.vraagOpties ul li:nth-child(2) {
  animation-delay: 300ms;
}

.vraagOpties ul li:nth-child(3) {
  animation-delay: 500ms;
}
.vraagOpties ul li:nth-child(4) {
  animation-delay: 700ms;
}

@keyframes stagger {
  0% {margin-left: 0px;}
  5% {margin-left: 2px;}
  10% {margin-left: -1px;}
  15% {margin-left: 0px;}
  100% {margin-left: 0px;}
}

.vraagOpties ul li:focus {
  box-shadow: inset 0px 0px 0px 5px var(--gold);
}

.vraagOpties ul li:last-child {
  margin: 0px 0px 0px 0px;
}

.vraagOpties ul li .antwoordNummer {
  display: inline-block;
  text-align: center;
  line-height: 20px;
  vertical-align: middle;  
  background: var(--floral-white);
  color: var(--green);
  border-radius: var(--radius);
  padding: 5px;
  margin: 0px 10px 0px 0px;
  width: 20px;
  height: 20px;
}

.diploma {
  user-select:text;
}
.diploma img {
  margin: 25px 0px 0px 0px;
  box-shadow: 0px 0px 0px 5px #FFF, 0px 2px 15px -5px #000;
  cursor: -webkit-grab; 
  cursor: grab;
}
.diploma img:hover:active {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.delen span {
  padding: 10px;
  display: block;
}

.delen a {
  padding: 0px 10px 10px 10px;
  margin-top: 5px;
  color: var(--blue);
  text-decoration: underline;
  display: block;
}
.delen a.social {
  border-radius: var(--radius);
  text-decoration: none;
  background: var(--blue);
  color: var(--floral-white);
}